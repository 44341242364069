<template>
    <v-container>
        <v-row>
            <v-col cols="6">
                <v-card flat>
                    <v-card-title>
                        <v-icon>mdi-calendar-clock</v-icon>
                        <span>Sorggruppe typer</span>
                    </v-card-title>
                    <v-card-text class="text--primary">
                        <p>Se oversikt og rediger ulike sorggruppe typer.</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn to="/sorg/administrer/gruppetyper/" outlined>Rediger</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="6">
                <v-card flat>
                    <v-card-title>
                        <v-icon>mdi-calendar-clock</v-icon>
                        <span>Sorggruppe metoder</span>
                    </v-card-title>
                    <v-card-text class="text--primary">
                        <p>Se oversikt og rediger ulike sorggruppe metoder.</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn to="/sorg/administrer/gruppemetoder/" outlined>Rediger</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="6"  v-if="user.id == '368a1b32-43cb-47c0-8013-8512f487239a'">
                <v-card flat>
                    <v-card-title>
                        <v-icon>mdi-account</v-icon>
                        <span>Sorggruppe medlemer</span>
                    </v-card-title>
                    <v-card-text class="text--primary">
                        <p>Sjekk deltakere til sorggruppemøter.</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn v-on:click="checkGriefActivities" outlined>Kjør</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import moment from 'moment';
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'GriefSettings',

    computed: {
        ...mapGetters('api', ['user']),
    },

    methods: {
        ...mapActions('api', ['request', 'formatActivity']),

        checkGriefActivities : async function() {
            const now = moment();

            let response = await this.request({
                method: 'get',
                url: '/sorggruppe',
                params: {
                    bareAktive: true,
                },
            });

            let debug = 0;
            if (response && Array.isArray(response)) {
                const sorggrupper = response;
                for (let sorggruppe of sorggrupper) {
                    //console.log(sorggruppe.tittel);

                    if (sorggruppe.moter) {

                            for (let mote of sorggruppe.moter) {

                                response = await this.request({
                                    method: 'get',
                                    url: '/aktivitet/' + mote.id,
                                });

                                if (response && typeof response.id != 'undefined') {
                                    const aktivitet = await this.formatActivity(response);
                                    if (aktivitet.start > now) {
                                        console.log(sorggruppe.tittel + " " + aktivitet.start.format('DD.MM.YYYY HH:mm'));

                                        /*for (const medlem of sorggruppe.deltakere) {
                                            if (!medlem.fjernet) {
                                                let finnes = false;
                                                for (const deltaker of aktivitet.deltakere) {
                                                    if (medlem.person.id == deltaker.person.id) {
                                                        finnes = true;
                                                    }
                                                }

                                                if (!finnes) {
                                                    console.log("Mangler: " + medlem.person.id + " - " + medlem.person.fornavn);
                                                    console.log(medlem);

                                                    debug += 1;
                                                }
                                            }
                                        }*/

                                        for (const deltaker of aktivitet.deltakere) {
                                            if (!deltaker.fjernet) {

                                                let finnes = false;
                                                for (const medlem of sorggruppe.deltakere) {
                                                    if (!medlem.fjernet && deltaker.person.id == medlem.person.id) {
                                                        finnes = true;
                                                    }
                                                }

                                                if (!finnes) {
                                                    console.log("Sluttet: " + deltaker.person.id + " - " + deltaker.person.fornavn + " " + deltaker.person.etternavn);
                                                    //console.log(deltaker);

                                                    const data = new FormData();
                                                    data.append('personId', [deltaker.person.id]);

                                                    await this.request({
                                                        method: 'delete',
                                                        url: '/aktivitet/' + aktivitet.id + '/deltaker',
                                                        data,
                                                    });

                                                    debug += 1;
                                                }
                                            }
                                        }
                                    }
                            }
                        }
                    }

                    /*if(debug > 30) {
                        break;
                    }*/
                }
            }

            console.log("Endringer:" + debug);
        }
    }
};
</script>
